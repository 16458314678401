





















import { Vue, Component, Prop } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';

@Component({
  components: {}
})
export default class SortableTableHeader extends Vue {
  @Prop(Boolean) public showArrow!: boolean;
  @Prop(String) public column!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';
  @Prop(Boolean) public sort!: boolean;
  @Prop() public classes!: string | string[];

  /**
   * Function to extract the class names passed from parent/consumer.
   */
  getClassNames(): string {
    if (this.classes && this.classes.length) {
      if (Array.isArray(this.classes)) {
        return this.classes.join(' ');
      }

      return this.classes;
    }

    return '';
  }
}
