





















import { Vue, Watch, Prop, Component } from 'vue-property-decorator';

@Component({})
export default class FileUpload extends Vue {
  @Prop() value!: File[] | null;
  @Prop() accept!: string;
  @Prop() multiple!: boolean;

  get dropFiles() {
    return this.value;
  }

  set dropFiles(value) {
    this.$emit('input', value);
  }
}
