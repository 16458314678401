




























import { Vue, Watch, Prop, Component } from 'vue-property-decorator';
import XLSX from 'xlsx';

@Component({
  components: {}
})
export default class CsvImportGuide extends Vue {
  @Prop() downloadSampleXlsx!: () => void;
  @Prop() downloadSampleCsv!: () => void;
  @Prop(Boolean) isSampleWithExistingData!: boolean;
}
