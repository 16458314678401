import { fromPairs, toPairs, uniq } from 'lodash';
import { from, fromEvent } from 'rxjs';
import { map, mergeMap, take, toArray } from 'rxjs/operators';
import XLSX from 'xlsx';

export class CsvXlxs {
  public static hasDuplicates(userListArray: string[][], emailIndex: number) {
    const emails = userListArray
      .map((a) => a[emailIndex])
      .map((s) => (s ? s.trim() : s));

    return uniq(emails).length !== emails.length;
  }

  public static processWorkbook(
    workbook: XLSX.WorkBook,
    headers: string[],
    emailIndex: number,
  ) {
    const userListJson: string[][] = XLSX.utils.sheet_to_json(
      workbook.Sheets[workbook.SheetNames[0]],
      {
        header: 1,
        raw: true,
      },
    );

    // remove first headed line in csv
    userListJson.shift();

    const filteredUserListJson = userListJson.filter((userList: any) => {
      return userList != null && userList.length > 0;
    });

    // check duplicate
    if (this.hasDuplicates(filteredUserListJson as string[][], emailIndex)) {
      throw new Error(`Uploaded file contains duplicated email addresses`);
    }

    if (filteredUserListJson.length > 500) {
      throw new Error(
        `Could not import users. Your file contains more than the limit of 500 users.`,
      );
    } else if (
      filteredUserListJson.length > 0 &&
      filteredUserListJson.length <= 500
    ) {
      return filteredUserListJson.map((user: string[]) => {
        // const [firstName, lastName, email] = user;

        const valuePairs = user.map((str, i) => [headers[i], str]);
        return fromPairs(valuePairs);

        // return {
        //   firstName,
        //   lastName,
        //   email,
        // };
      });
    } else {
      throw new Error(
        // tslint:disable-next-line: max-line-length
        `Uploaded file contains no data, please upload a new file containing data according to the provided format.`,
      );
    }
  }

  public static async loadCsv(
    csvFile: File,
    headers: string[],
    emailIndex: number,
  ): Promise<any[] | unknown> {
    const file = csvFile;
    const reader = new FileReader();

    reader.readAsText(file);

    return fromEvent(reader, 'load')
      .pipe(
        map((e: Event) => {
          // @ts-ignore
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          return workbook;
        }),
        map((workbook) => this.processWorkbook(workbook, headers, emailIndex)),
        take(1),
        mergeMap((arr: Array<{ [s: string]: string }>) => from(arr)),
        map((obj) =>
          fromPairs(toPairs(obj).map(([key, value]) => [key, String(value)])),
        ),
        toArray(),
      )
      .toPromise();
  }

  public static async loadXlsx(
    xlsxFile: File,
    headers: string[],
    emailIndex: number,
  ): Promise<any[] | unknown> {
    const file = xlsxFile;
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    return fromEvent(reader, 'load')
      .pipe(
        map((e: Event) => {
          // @ts-ignore
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          return workbook;
        }),
        map((workbook) => this.processWorkbook(workbook, headers, emailIndex)),
        take(1),
        mergeMap((arr: Array<{ [s: string]: string }>) => from(arr)),
        map((obj) =>
          fromPairs(toPairs(obj).map(([key, value]) => [key, String(value)])),
        ),
        toArray(),
      )
      .toPromise();
  }

  public static toBlob(workbook: any) {
    const buffer = new ArrayBuffer(workbook.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < workbook.length; i++) {
      /* tslint:disable:no-bitwise */
      view[i] = workbook.charCodeAt(i) & 0xff;
    }
    return buffer;
  }

  public static downloadSampleXlsx(
    worksheetData: string[][],
    name = 'Sample',
    props: XLSX.FullProperties,
  ) {
    const workbook = XLSX.utils.book_new();
    workbook.Props = props;
    const sheetName = name.slice(0, 30);
    workbook.SheetNames.push(sheetName);
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    workbook.Sheets[sheetName] = worksheet;
    const xlsxWorkbook = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'binary',
    });
    const xlsxBlob = this.toBlob(xlsxWorkbook);
    saveAs(
      new Blob([xlsxBlob], { type: 'application/octet-stream' }),
      `${name}.xlsx`,
    );
  }

  public static downloadSampleCsv(worksheetData: string[][], name = 'Sample') {
    const dataHeader = worksheetData[0];
    worksheetData = worksheetData.slice(1);
    const csvDataEntries = worksheetData.map((strArr) => {
      return fromPairs(
        strArr.map((str, i) => {
          return [dataHeader[i], str];
        }),
      );
    });
    const csvData = csvDataEntries;

    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const csv = XLSX.utils.sheet_to_csv(worksheet);

    const csvBlob = this.toBlob(csv);

    saveAs(
      new Blob([csvBlob], { type: 'application/octet-stream' }),
      `${name}.csv`,
    );
  }

  public static isCsv(file: File) {
    return (
      file?.type === 'text/csv' || file?.name?.toLowerCase()?.endsWith?.('.csv')
    );
  }

  public static isXlsx(file: File) {
    return (
      file?.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file?.name?.toLowerCase()?.endsWith?.('.xlsx')
    );
  }
}
