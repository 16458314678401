/* tslint:disable */
import store, { RootState } from '@/store/store';
import { partial as _partial } from 'lodash';
import Vue from 'vue';
import { DispatchOptions } from 'vuex';
import { AuthApiState } from '../store/modules/auth/types/auth.types';
import { ApiState } from '../store/types/general.types';

export const useStore = function (this: Vue) {
  return this.$store;
};
export const useRootState = function (this: Vue) {
  return this.$store.state as RootState;
};
export const useAuthState = function (this: Vue) {
  return useRootState.call(this).auth;
};
export const useWebsocketState = function (this: Vue) {
  return useRootState.call(this).websocket;
};

type AllApiState = AuthApiState;

const splitNamespacedAction = (namespacedAction: string) => {
  const [moduleName, actionName] = namespacedAction.trim().split('/') as [
    keyof Omit<RootState, 'websocket'>,
    keyof AllApiState,
  ];
  return { moduleName, actionName };
};

export const useApiState = function (this: Vue, namespacedAction: string) {
  const { moduleName, actionName } = splitNamespacedAction(namespacedAction);
  try {
    return (store.state[moduleName].apiState as AllApiState)[
      actionName
    ] as ApiState;
  } catch (error) {
    return undefined;
  }
};

export const useAction = function (this: Vue, namespacedAction: string) {
  return _partial(store.dispatch, namespacedAction) as (
    payload?: any,
    options?: DispatchOptions,
  ) => Promise<any>;
};

export const useGetter = function (this: Vue, namespacedGetter: string) {
  return useStore.call(this).getters[namespacedGetter];
};
